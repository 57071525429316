.App {
    min-height: 100vh;
    width: 100%;
    font-family: Sahel FD, sans-serif;
    margin: 0;
    padding: 0;
}

.icons {
    color: #fff;
}

.form-check-input {
    width: 17px !important;
}

.ltr {
    direction: ltr;
    text-align: right;
    font-family: Sahel FD, sans-serif !important;
}

.hidden {
    display: none!important;
}

.buttons {
    margin: 15px 0;
}

.hideBtn {
    visibility: hidden;
}